/** by.Odin
 * payment sales 공통타입 정의
 * [warning] 해당 Method명 앞 대문자, 사용시 주의!
 */
// 사용시 아래 형식으로 import 사용
// import {
//     PayTypeOpts, 
//     TaxStatOpts, 
//     PayStatOpts, 
//     SendStatOpts, 
//     PayMethods, 
//     AcquCodes, 
//     BankCodes,
// } from '../../../common/salesType';

let type = {};

type.PayTypeOpts = {
    '9999':'주문생성',
    '0000':'결제대기',
    '1000':'현금(계좌)',
    '0001':'쿠폰',
    '0010':'포인트',
    '0011':'포인트|쿠폰',
    '0100':'카드',
    '0101':'카드|쿠폰',
    '0110':'카드|포인트',
    '0111':'카드|포인트|쿠폰',
    '2000':'후불',
    '1001':'현금|쿠폰',
    '1010':'현금|포인트',
    '1011':'현금|포인트|쿠폰',
    '1100':'현금|카드',
    '1101':'현금|카드|쿠폰',
    '1110':'현금|카드|포인트',
    '1111':'현금|카드|포인트|쿠폰',
}

type.TaxStatOpts = {
    '99':'미발행',
    '01':'영수계산서대상',
    '02':'청구계산서대상',
    '03':'현금영수증대상',
    '04':'영수증발행대상',
    '11':'영수발행',
    '12':'청구발행',
    '13':'현금영수증발행',
    '14':'영수증발행'
}

type.PayStatOpts = {'99': '미결제', '10': '부분결제', '20': '초과결제', '00': '결제완료', '09':'결제취소', '19':'부분결제취소', '29':'초과결제취소'}

type.SendStatOpts = {'99':'미발송', '10':'구매확인', '20':'발송준비', '90':'발송에러', '00':'발송완료'}

type.PayMethods = {'CARD':'신용카드', 'BANK':'계좌이체', 'VBANK':'가상계좌', 'CELLPHONE':'휴대폰', 'CLGIFT':'문화상품권', 'CASHRCPT' : '현금영수증', 'KAKAO' : '카카오', 'NAVER' : '네이버'}

type.AcquCodes = {'01':'비씨', '02':'국민', '03':'하나', '04':'삼성', '05':'', '06':'신한', '07':'현대', '08':'롯데', '12':'농협', '16':'하나카드'}

type.BankCodes = {
    '001': '한국은행', '002': '산업은행', '003': '기업은행', '004': '국민은행', '005': '외환은행',
    '007': '수협중앙회', '008': '수출입은행', '011': '농협중앙회', '012': '농협회원조합', '020': '우리은행',
    '023': 'SC 제일은행', '027': '한국시티은행', '031': '대구은행', '032': '부산은행', '034':'광주은행',
    '035':'제주은행', '037':'전북은행', '039':'경남은행', '045':'새마을금고연합회', '048':'신협중앙회',
    '050':'상호저축은행', '081':'하나은행', '088':'신한은행', '051':'중국은행', '052':'모건스텐리은행',
    '054':'HSBC 은행', '055':'도이치은행', '056':'에이비엔암로은행', '057':'제이피모간체이스은행', '058':'미즈호코퍼레이트은행',
    '059':'미쓰비스도쿄 UFJ 은행', '060':'BOA', '071':'정보통신부 우체국', '076':'신용보증기금', '077':'기술신용보증기금',
    '093':'한국주택금융공사', '094':'서울보증보험', '095':'경찰청', '099':'금융결제원', '209':'동양종합금융증권(유안타)',
    '218':'현대증권', '230':'미래에셋증권', '238':'대우증권', '240':'삼성증권', '243':'한국투자증권',
    '247':'우리투자증권', '261':'교보증권', '262':'하이투자증권', '263':'에이치엠씨투자증권', '264':'키움증권',
    '265':'이트레이드증권', '266':'에스케이증권53 267 대신증권', '268':'솔로몬투자증권', '269':'한화증권', '270':'하나대투증권',
    '278':'굿모닝신한증권', '279':'동부증권', '280':'유진투자증권', '287':'메리츠증권', '289':'엔에이치투자증권',
    '290':'부국증권', '291':'신영증권', '292':'엘아이지투자증권', 'J01':'미쓰이스미모토', '089':'K 뱅크',
    '090':'카카오뱅크'
}

module.exports = type;