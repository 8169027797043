<template>
    <div class="d-flex content-center min-vh-100">
        <BContainer>
            <BRow class="justify-content-center">
                <BCol md="8">
                    <BCard class="text-left" footer-tag="footer" footer-bg-variant="light">
                        <div slot="header">
                            <strong>주문 내역</strong>
                            <div class="card-header-actions">
                                <small class="text-muted">주문 및 결제정보</small>
                            </div>
                        </div>
                        <BRow>
                            <BCol>

                                <BCard class="mb-2"
                                       border-variant="info"
                                       header="info"
                                       header-bg-variant="info"
                                       header-text-variant="white" >
                                    <div slot="header">
                                        <strong>주문 정보</strong>
                                        <div class="card-header-actions">
                                            주문번호: <span class="text-primary font-weight-bold">{{ orderNumber }}</span>
                                        </div>
                                    </div>

                                    <BInputGroup  class="mb-2" id="email">
                                        <BInputGroupPrepend is-text>
                                            <BIconBagCheckFill class="mr-1"/> 제품명
                                        </BInputGroupPrepend>
                                        <BFormInput class="font-weight-bold bg-white" :value="order.disp_name +' ('+ order.prd_name+')'" type="text" readonly/>
                                    </BInputGroup>

                                    <BInputGroup  class="mb-2" id="email">
                                        <BInputGroupPrepend is-text>
                                            <BIconAt class="mr-1"/> 이메일
                                        </BInputGroupPrepend>
                                        <BFormInput class="bg-white" :value="order.email" type="email" readonly/>
                                    </BInputGroup>


                                    <BInputGroup  class="mb-2">
                                        <BInputGroupAppend is-text><BIconCashStack class="mr-3"/> 금 액</BInputGroupAppend>
                                        <BFormInput class="text-right font-weight-bold bg-white" v-model="totalPrice" readonly/>
                                        <BInputGroupAppend is-text>단 가</BInputGroupAppend>
                                        <BFormInput class="text-right" v-model="order.unit_price" readonly/>
                                        <BInputGroupAppend is-text>수량</BInputGroupAppend>
                                        <BFormInput  v-model="order.qty"
                                                    class="text-right"
                                                    type="number"
                                                    readonly/>
                                    </BInputGroup>


                                    <BInputGroup  class="mb-2">
                                        <BInputGroupPrepend is-text>
                                            <BIconPersonCircle class="mr-1"/> 주문자
                                        </BInputGroupPrepend>
                                        <BFormInput class="bg-white" v-model="order.name" readonly/>
                                        <BInputGroupAppend is-text>아이디</BInputGroupAppend>
                                        <BFormInput class="bg-white" v-model="order.cu_id" readonly/>
                                    </BInputGroup>

                                    <BInputGroup  class="mb-2">
                                        <BInputGroupPrepend is-text>
                                            <BIconTelephone class="mr-1"/> 연락처
                                        </BInputGroupPrepend>
                                        <BFormInput class="bg-white" v-model="order.phone_no" readonly/>
                                    </BInputGroup>
                                    <BInputGroup  class="mb-2">
                                        <BInputGroupPrepend is-text> <BIconJournal class="mr-3"/> 메 모</BInputGroupPrepend>
                                        <BFormInput class="bg-white" v-model="order.description" readonly/>
                                    </BInputGroup>


                                    <BInputGroup  class="mb-1" v-show="false">
                                        <BInputGroupPrepend is-text>
                                            <BIconUiChecksGrid class="mr-3"/> 구 분
                                        </BInputGroupPrepend>
                                        <BFormSelect class="bg-white" :value="order.tax_stat" :options="taxStatOpts" disabled/>
                                    </BInputGroup>
                                    <BRow v-if="payment===null">
                                        <BCol class="text-right mt-3">
                                            <h3 class="font-weight-bold text-muted">총 결제금액: <span class="text-danger">{{totalPrice}}</span></h3>
                                        </BCol>
                                    </BRow>

                                </BCard>

                                <div id="compInfo" v-show="order.tax_stat==='01'">
                                    <BCard class="m-0"
                                           border-variant="info"
                                           header="info"
                                           header-bg-variant="info"
                                           header-text-variant="white" >
                                        <div slot="header">
                                            <strong>사업자 등록 정보</strong>
                                            <div class="card-header-actions">
                                                <small>계산서 발행을 위한 정보입니다.</small>
                                            </div>
                                        </div>

                                        <BInputGroup  class="mb-2">
                                            <BInputGroupPrepend is-text>
                                                <BIconCreditCard2Front class="mr-1"/>  회사명
                                            </BInputGroupPrepend>
                                            <BFormInput class="bg-white" v-model="order.comp_name" type="text" autocomplete="comp_name" readonly/>
                                        </BInputGroup>

                                        <BInputGroup  class="mb-2">
                                            <BInputGroupPrepend is-text>
                                                <BIconCreditCard2Front class="mr-1"/> 대표자명
                                            </BInputGroupPrepend>
                                            <BFormInput class="bg-white" v-model="order.pr_name" type="text" readonly/>
                                        </BInputGroup>

                                        <BInputGroup  class="mb-2">
                                            <BInputGroupPrepend is-text>
                                                <BIconCardList class="mr-1"/> 업태/업종
                                            </BInputGroupPrepend>
                                            <BFormInput class="bg-white" v-model="order.biz_kind" type="text" readonly/>
                                        </BInputGroup>


                                        <BInputGroup  class="mb-2">
                                            <BInputGroupPrepend is-text>
                                                <BIconBuilding class="mr-1"/> 사업자번호
                                            </BInputGroupPrepend>
                                            <BFormInput class="bg-white" v-model="order.comp_no" type="text" readonly/>
                                        </BInputGroup>


                                        <BInputGroup  class="mb-2">
                                            <BInputGroupPrepend is-text>
                                                <BIconMap class="mr-1"/> 주 소
                                            </BInputGroupPrepend>
                                            <BFormInput class="bg-white" v-model="order.address" readonly/>
                                        </BInputGroup>
                                    </BCard>
                                </div>

                                <div id="payInfo" v-if="payment!==null">
                                    <BCard class="m-0"
                                           border-variant="primary"
                                           header="primary"
                                           header-bg-variant="primary"
                                           header-text-variant="white" >
                                        <div slot="header">
                                            <strong>결제 정보</strong>
                                            <div class="card-header-actions">
                                                <small>주문제품의 결제 정보입니다</small>
                                            </div>
                                        </div>

                                        <BInputGroup  class="mb-1">
                                            <BInputGroupPrepend is-text>
                                                <BIconCreditCard2Front class="mr-2"/>  결 제 일
                                            </BInputGroupPrepend>
                                            <BFormInput class="bg-white" :value="order.pay_dt" type="text"  readonly/>
                                        </BInputGroup>

                                        <BInputGroup  class="mb-1">
                                            <BInputGroupPrepend is-text>
                                                <BIconCreditCard2Front class="mr-1"/> 결제방식
                                            </BInputGroupPrepend>
                                            <BFormInput class="bg-white" :value="payMethods[payment.PayMethod]" type="text" readonly/>
                                        </BInputGroup>

                                        <BInputGroup  class="mb-1">
                                            <BInputGroupPrepend is-text>
                                                <BIconCreditCard2Front class="mr-1"/> 결제금액
                                            </BInputGroupPrepend>
                                            <BFormInput class="bg-white" :value="payment.Amt" type="text" readonly/>
                                        </BInputGroup>

                                        <BInputGroup  class="mb-1">
                                            <BInputGroupPrepend is-text>
                                                <BIconCreditCard2Front class="mr-1"/> 카드사명
                                            </BInputGroupPrepend>
                                            <BFormInput class="bg-white" :value="payment.fn_name" type="text" readonly/>
                                        </BInputGroup>
                                        <BInputGroup  class="mb-1">
                                            <BInputGroupPrepend is-text>
                                                <BIconCreditCard2Front class="mr-1"/> 카드번호
                                            </BInputGroupPrepend>
                                            <BFormInput class="bg-white" :value="payment.CardNum" type="text" readonly/>
                                        </BInputGroup>
                                        <BInputGroup  class="mb-1">
                                            <BInputGroupPrepend is-text>
                                                <BIconCreditCard2Front class="mr-1"/> 할부개월
                                            </BInputGroupPrepend>
                                            <BFormInput class="bg-white" :value="payment.CardQuota" type="text" readonly/>
                                        </BInputGroup>
                                        <BInputGroup  class="mb-1">
                                            <BInputGroupPrepend is-text>
                                                <BIconCreditCard2Front class="mr-1"/> 승인번호
                                            </BInputGroupPrepend>
                                            <BFormInput class="bg-white" :value="payment.AuthCode" type="text" readonly/>
                                        </BInputGroup>
                                        <BInputGroup  class="mb-1">
                                            <BInputGroupPrepend is-text>
                                                <BIconCreditCard2Front class="mr-1"/> 결제결과
                                            </BInputGroupPrepend>
                                            <BFormInput class="bg-white" :value="payment.ResultMsg" type="text" readonly/>
                                        </BInputGroup>

                                    </BCard>
                                </div>

                                <BRow class="mt-3" v-if="payment===null">
                                    <BCol sm="8" class="text-right">
                                        <BFormCheckbox v-model="order.agreement" value="Y" unchecked-value="N">
                                            약관에 모두 동의 합니다.
                                        </BFormCheckbox>
                                    </BCol>
                                    <BCol class="text-right">
                                        <BButton size="sm" v-b-modal.termsModal variant="info">약관보기</BButton>
                                    </BCol>
                                </BRow>

                                <BRow v-if="order.pay_stat==='00'" class="mt-1">
                                    <BCol class="text-center">
                                        <BButton size="sm" @click="gotoXcostMe" variant="primary"> Go To XCOST.ME </BButton>
                                    </BCol>
                                </BRow>

                            </BCol>
                        </BRow>
                        <BRow>
                            <BCol>
                                <li class="list-group-item bg-dark text-white mt-2 mt-0" v-if="order.pay_stat==='00'">
                                    <strong>결제 취소 및 구매 철회 안내</strong><br/>
                                    <span class="text-muted">
                                        결제 후 15일 이내 구매를 취소 할 수 있으며, 제품키 및 사용권한 등은 <u>취소시 즉시 삭제</u>됩니다.<br/>
                                        <b>단, 제품키를 인증하였거나 유료서비스를 이용한 경우에는 구매 철회를 할 수 없습니다.</b><br/>
                                        구매 철회시 <B><u>주문번호 및 취소사유를 기재</u></B>하여 이메일(<b>estimate@howcost.co.kr</b>)로 보내주시기 바랍니다..<br/>
                                        관리자 확인 후 취소까지 최대 7일이 소요될 수 있습니다.
                                    </span>
                                </li>
                            </BCol>
                        </BRow>



                        <BRow v-if="order.pay_stat!=='00'">
                            <BCol class="text-center">
                                <BButtonGroup>
                                    <BButton type="button" variant="primary" class="mr-2" @click="payWithCash" :disabled="isSubmiting||isCardSubmiting">
                                        <BSpinner small v-show="isSubmiting" class="mr-1"></BSpinner>
                                        <BIconCash/> <strong>현금 결제</strong>
                                    </BButton>

                                    <BButton type="button" variant="warning" class="mr-4" @click="payWithCreditCard" :disabled="isSubmiting||isCardSubmiting">
                                        <BSpinner small v-show="isCardSubmiting" class="mr-1"></BSpinner>
                                        <BIconCreditCardFill/> <strong>신용카드 결제</strong>
                                    </BButton>

                                    <BButton type="button" @click="resetButton" :disabled="!isCardSubmiting" variant="danger">
                                       <strong>Reset</strong>
                                    </BButton>

                                </BButtonGroup>
                            </BCol>
                        </BRow>



                        <div slot="footer">


                            <div class="small text-muted">
                                <strong><i class="fa fa-copyright"></i> <a href='http://qqkorea.net'>(주)하우코스트</a></strong>
                                <span class="hidden-xs en">All rights reserved.</span><br>
                                <span class="hidden-xs en">
                                    대표자 : 유현오 | E-Mail : <a href="mailto:ceo@howcost.co.kr">ceo@howcost.co.kr</a>) <br>
                                    <a href='http://xcost.me/bbs/qalist.php'>적산문의/프로그램/문의 1:1상담</a> | tel. 031-417-0466 | fax. 02-6442-0466 <br/>
                                    사무실 : 경기도 수원시 권선구 수성로 8 경기중소기업성장지원센터 303호 <br/>부설연구소 : 건설계약연구소/벤쳐기업인증/연구개발서비스업등록/소프트웨어개발업/ISO9001,ISO14001인증<br/>
                                    사업자번호 : 809-86-00250</span>
                            </div>
                        </div>

                    </BCard>


                </BCol>
            </BRow>
        </BContainer>

        <b-modal id="termsModal" size="xl" scrollable title="이용약관">
            <BFormTextarea
                    v-model="termsText"
                    rows="100"
                    max-rows="100"
                    disabled
            ></BFormTextarea>
        </b-modal>

    </div>
</template>

<script>
import {
    apiCall,
    cloneVar,
    alertError,
    alertWarn,
    alertSuccess,
    alertConfirm,
    checkBisNo,
    commify,
    toastSync,
    warpSite,
} from '../../../common/utils';
    import terms from "./_terms";
    import DaumPostcode from 'vuejs-daum-postcode';
    import moment from "moment";
    import { PayTypeOpts, PayMethods } from '../../../common/salesType';

    const _order = {
        prd_name: '',
        disp_name: '',
        prd_code     : null,
        dept_code    : null,
        unit_price   : 0,
        qty          : 0,
        warranty_mon : null,
        price        : 0,
        pay_amt      : null,
        pay_type     : null,
        cu_seq       : null,
        cu_id        : null,
        ord_dt       : null,
        email        : null,
        name         : null,
        phone_no     : null,
        comp_no      : null,
        comp_name    : null,
        pr_name      : null,
        address      : null,
        zip_code     : null,
        appr_yn      : null,
        appr_dt      : null,
        send_stat    : null,
        send_dt      : null,
        pay_stat     : null,
        pay_dt       : null,
        tax_stat     : null,
        tax_dt       : null,
        tax_seq      : null,
        biz_kind     : null,
        cncl_yn      : null,
        cncl_dt      : null,
        cncl_desc    : null,
        description  : '',
        reg_dt       : null,
        upd_dt       : null,
        reg_id       : null,
        upd_id       : null,
        agreement    : null,
        auth_no      : null,
        addr_detail  : '',
        auth_seq     : null,
        trs_no       : null,
        cncl_no      : null,
    };


    export default {
        name: 'OrderDetail',
        components: {
            DaumPostcode
        },
        props: ['orderToken'],
        data () {
            return {
                orderNumber: null,
                prdCode: '2106-0002',
                order: cloneVar(_order),
                isEmailChecked: false,
                termsText: terms[0].text,
                taxStatOpts: [
                    { value: null, text: '개인/사업자 선택' },
                    { value: '03', text: '개인' },
                    { value: '01', text: '사업자' },
                ],
                isSubmiting: false,
                isCardSubmiting: false,
                isCanceling: false,
                isEmailSending:false,
                prdInfo: null,
                imgSrc: null,
                unitPrice: null,
                totalPrice: 0,
                orderEnabled: false,
                prdCodeOpts: [
                    { text:'2개(1+1)', value:'2106-0002' },
                    { text:'1개', value:'2106-0001' },
                ],
                payTypeOpts: PayTypeOpts,
                payMethods: PayMethods,
                payment: null,
            }
        },
        async created(){

            // console.log("-------- created: OrderForm ----- prdCode : " + this.prdCode );

            try{
                // await this.getPrdInfo();
                await this.getOrderInfo();


                this.unitPrice = commify(this.order.unit_price) + ' 원';
                this.totalPrice = commify(this.order.price) + ' 원';

            }catch(e){

            }
        },
        beforeMount() {

        },
        mounted(){
            console.log("-------- mounted: OrderForm ----- prdCode : " + this.prdCode );

        },

        computed: {
            compnoState: function(){
                return checkBisNo(this.order.comp_no);
            }
        },
        methods: {
            addressModalShow(){
                this.$bvModal.show("addressModal");
            },
            calcPrice(){
                this.order.price = this.prdInfo.unit_price * this.order.qty;
                this.totalPrice = commify(this.order.price) + ' 원';
            },
            gotoXcostMe(){
                window.location.href = warpSite();
            },

            resetButton(){
                this.isCardSubmiting = false;
            },

            async getOrderInfo(){
                console.log( "getOrderInfo ---> ", this.orderToken);
                try{
                    const r = await apiCall('post', `/api/order/info`, {orderToken: this.orderToken});
                    if(!r.result){
                        await alertWarn( this.$bvModal, '주문정보 링크가 만료되었습니다', '주문정보 없음' );
                        return;
                    }
                    const order = r.result;
                    this.order = order;
                    this.orderNumber = order.ord_dt.replace(/-/g,'').substr(0,8)+'-'+('-00000000'+order.ord_no).slice(-8);

                    if(this.order.pay_stat==='00' && this.order.trs_no ){
                        await this.getPaymentInfo( this.order.trs_no );
                    }


                }catch(err){
                    console.log( err );
                }
            },

            async selectPrdCode(){
                await this.getPrdInfo();

                try{
                    if( this.prdCode==='2106-0002'){
                        this.order.qty = 2;
                    }else{
                        this.order.qty = 1;
                    }
                    this.order.price = this.prdInfo.unit_price * this.order.qty;
                    this.unitPrice = this.prdInfo.unit_price;
                    this.totalPrice = commify(this.order.price) + ' 원';
                }catch(err){
                    console.log(err);
                }

            },



            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },

            async getPrdInfo(){
                console.log( "getPrdInfo ---- "+this.prdCode );

                try{
                    const r = await apiCall('get', `/api/order/prd-info/${this.prdCode}`);
                    if(r.result){
                        this.prdInfo = r.result[0];
                        this.imgSrc = $baseURL + "/images/" + this.prdInfo.file_nm;
                        //console.log( this.prdInfo );
                        //console.log( this.imgSrc );
                    }
                }catch(err){
                    console.log(err);
                }
            },

            async getPaymentInfo(trsNo){
                console.log( "getPaymentInfo ---- " + trsNo);

                try{
                    const param = { orderToken: this.orderToken, trsNo: trsNo };
                    const r = await apiCall('post', `/api/payment/info`, param);

                    if(r.result){
                        this.payment = r.result[0];
                        //console.log( this.prdInfo );
                        //console.log( this.imgSrc );
                    }
                }catch(err){
                    console.log(err);
                }
            },


            async payWithCash(){
                console.log( "-------payWithCash---------" );

                try{

                    if( this.order.agreement!=="Y" ){
                        await alertWarn(this.$bvModal, "약관 동의가 필요합니다", "약관 동의 확인");
                        return;
                    }

                    this.isSubmiting = true;
                    this.order.prd_code = this.prdCode;
                    const r = await apiCall('post', `/api/payment/cash`,  {orderToken: this.orderToken});
                    // const r = await apiCall('post', `/api/order/confirm`, this.order);
                    //console.log( "onSubmit ----------------> result: ", r );
                    if(r.code===200){
                        await alertSuccess(this.$bvModal, '주문 내용 및 결제 방법은 이메일을 확인하시기 바랍니다.');
                        window.location.href = warpSite();
                        return;
                    }else{
                        await alertError(this.$bvModal, `[ERROR] ${r.message}`);
                    }
                    this.isSubmiting = false;

                }catch(err){
                    console.log(err);
                }finally{
                    this.isSubmiting = false;
                }

            },


            async payWithCreditCard(){
                console.log( "-------- payWithCreditCard -------- " );

                try{
                    if( this.order.agreement!=="Y" ){
                        await alertWarn(this.$bvModal, "약관 동의가 필요합니다", "약관 동의 확인");
                        return;
                    }


                    this.isCardSubmiting = true;
                    const r = await apiCall('post', `/api/payment/gateway`, {orderToken: this.orderToken});
                    if(r.code===200){
                        const rr = r.result;
                        await this.submitPayWindow(rr.pgData, rr.ret);

                    }else{
                        alert(r.message);
                    }

                }catch(err){
                    alert(err.message);
                    console.log("error on payWithCreditCard", err);
                }

            },

            async submitPayWindow(pgData, obj){
                const frm = document.createElement('form');
                frm.setAttribute('charset', "euc-kr");
                frm.setAttribute('accept-charset', "euc-kr");
                frm.setAttribute('name', 'tranMgr');
                frm.setAttribute('action', obj.ActionURL);
                frm.setAttribute('target', 'payWindow');
                Object.keys(pgData).forEach( k => {
                    const hField = document.createElement("input");
                    hField.setAttribute('type', 'hidden');
                    hField.setAttribute('name',  k);
                    hField.setAttribute('value', pgData[k]);
                    frm.appendChild( hField );
                });
                console.log( pgData );

                document.body.appendChild(frm);
                const popupX = (window.screen.width / 2) - (545 / 2);
                const popupY = (window.screen.height /2) - (573 / 2);
                const winopts= "width=545,height=573,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=no,resizable=no,left="+ popupX + ", top="+ popupY + ", screenX="+ popupX + ", screenY= "+ popupY;
                const win =  window.open("", "payWindow", winopts);

                try{
                    if(win == null || win.closed || typeof win.closed == 'undefined' || win.screenLeft == 0) {
                        alert('브라우저 팝업이 차단으로 설정되었습니다.\n 팝업 차단 해제를 설정해 주시기 바랍니다.');
                        return false;
                    }
                }catch(e){}

                frm.submit();
            },

            resetForm() {
                this.order = cloneVar(_order);
                this.isEmailChecked = false;

                this.$nextTick(() => {
                    this.$refs.observer.reset();
                });
            },

        },

    }
</script>
